<template>
  <div>
   <div class="popup__text">Do you want to delete the autobid?</div>
    <loader v-if="isLoading"/>
    <div class="popup__buttons theme--dark" v-else>
      <div class="btn btn--main btn--md btn--block btn--flex" @click="removeAutoBidLimit">Remove</div>
      <div class="btn btn--sm btn--primary btn--icon" @click="closePopup">Cancel</div>
    </div>
  </div>
</template>

<script>
import loader from "@/components/Loader/Loader";
import {AUCTION_EVENT_IAAI, IAAI_EVENT_AUTO_BID_REMOVE_EVENT} from "@/consts";
import {mapGetters} from "vuex";

export default {
  props: ['payload', 'close'],
  data: function () {
    return {
      autoBidLimit: null,
      error: '',
      isLoading: false
    }
  },
  components: {
    loader
  },
  name: "RemoveAutoBid",
  methods: {
    removeAutoBidLimit() {
      this.isLoading = true;
      this.$iaaiService.iaaiClient.removeAutoBid(this.payload.InternalAuctionId, this.payload.InternalAuctionItemId, this.getUserId);
    },
    closePopup() {
      this.close();
    }
  },
  created() {
    const self = this
    /**
     * event subscription
     */
    this.$iaaiService.iaaiEventBus.$on(AUCTION_EVENT_IAAI + self.payload.InternalAuctionId, async function (payload) {
      if(payload.iaaiEvent === IAAI_EVENT_AUTO_BID_REMOVE_EVENT) {
        self.isLoading = !self.isLoading
        self.closePopup();
      }
    });
  },
  computed: {
    ...mapGetters(['userId']),
    getUserId() {
      return this.userId;
    }
  }
}
</script>

<style scoped>

</style>